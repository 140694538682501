/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.home {
  text-align: center;
  padding: 40px 20px;
  background-color: #ee82ad;
}

.home h1 {
  font-size: 2.5em;
  color: #fff;
  margin-bottom: 10px;
  font-family: 'Roboto', Arial, sans-serif;
}

.home p {
  font-size: 1.2em;
  color: #fff;
  margin-bottom: 30px;
  font-family: 'Roboto', Arial, sans-serif;
}