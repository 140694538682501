.about {
    text-align: center;
    padding: 50px;
    color: white;
    min-height: 100vh;
  }
  
  .about-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-content h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .about-content p {
    font-size: 1.5em;
  }