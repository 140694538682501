.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ee82ad;
    height:80px;    
  }
  .navbar-logo {
    display: flex;
    margin-top: 60px;
    overflow: visible;
  }

  .navbar-logo img {
    margin-right: 20px;
  }

  .navbar-logo img {
    height: 150px;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    font-size: 24px;
  }
  
  .navbar-right {
    display: flex;
    gap: 20px;
  }

  .navbar-links a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  